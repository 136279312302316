<template>
  <div>
    <b-modal
      id="modal-checkin"
      v-model="isOpen"
      :size="isShowNationSexCheckIn == 1 ? 'xl' : 'lg'"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <p class="mb-0 title-modal color-primary">
            {{ $t('golf_booking_check_in') }}
          </p>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-row style="margin-left:10px;margin-top: 10px;">
          <b-form-checkbox
              class="content-body-modal"
              v-model="isCheckAll"
              style="margin-right: 4px"
              @change="checkAll(isCheckAll)"
            />
            <span>{{ $t('golf_booking_check_in_printbdc_all') }}</span>
        </b-row>
      <b-card-body style="max-height: 450px; overflow-y: auto;">
        <div
          v-for="(item ,indexItem) in items" 
          :key="indexItem + 'item'"
          class="checkin-body mt-1"
        >
          <b-row>
            <b-form-checkbox
              class="content-body-modal"
              v-model="item.Checked"
              style="margin-right: 4px"
              @change="checkItem(item)"
            />
            <span class="mb-0 content-body-modal">
            {{ convertUTC(item.OpenDate, 'DD/MM/YYYY HH:mm') }} -
            {{ item.TeeName }}
            <span v-if="item.GroupName">
              ({{ item.GroupName }})
            </span>
          </span>
          </b-row>
            
          
          <b-row
            v-for="(member,index) in item.Members"
            :key="index +'mem'"
          > 
            <!-- <b-col md="1" class="block" >
              <b-form-checkbox
                v-model="member.Checked"
                style="margin-right: 4px"
                @change="checkItem()"
              />
              In BDC
            </b-col> -->
            <b-col
              md="3"
              class="block fullname"
            >
              <b-form-checkbox
                  v-model="member.Checked"
                  style="margin-right: 4px"
                />
              <div>
                <p style="font-weight: bold">
                  {{ member.BookingDetail.FullName }}
                </p>
                <p>
                  Caddy: {{ member.CaddyCode }} - {{ member.CaddyName }}
                </p>
              </div>
            </b-col>
            <b-col
              :md="isShowNationSexCheckIn == 1 ? 1 : 2"
              class="block"
            >
              <div
                class="dot-size-10 d-inline-block rounded-circle"
                :style="'background-color: ' + member.BookingDetail.GolfClassColor"
              />
              <span class="text-truncate">
                {{ member.BookingDetail.GolfClassName }}
              </span>
            </b-col>
            <b-col
              md="1"
              class="block"
            >
              <ez-icon
                icon="ezGolf-icon-golf-tee"
                :size="13"
              />
              <div>{{ member.BookingDetail.TotalNumberOfHole }} hole</div>
            </b-col>
            <b-col
              :md="isShowNationSexCheckIn == 1 ? 1 : 2"
              class="block w-100"
            >
              <b-form-input
                placeholder="BDC"
                v-model="member.BDC"
                :autofocus="index === 0 && indexItem === 0 && member.BookingDetail.BookingDetailCode === '' ?   true : false "
                :tabindex="defaultCheckIn === 'DEFAULT_CHECKIN_ALL' ? member.indexTabAll + 1 : member.indexTabOne + 1 "
                :disabled="member.BookingDetail.BookingDetailCode ? true : false"
                @keyup.enter="nextForcus(defaultCheckIn === 'DEFAULT_CHECKIN_ALL' ? member.indexTabAll + 1 : member.indexTabOne + 1 )"
              />
            </b-col>
            <b-col
              :md="isShowNationSexCheckIn == 1 ? 1 : 2"
              class="block w-100"
            >
              <b-form-input
                :tabindex="defaultCheckIn === 'DEFAULT_CHECKIN_ALL' ? member.indexTabAll + 2 : defaultCheckIn === 'DEFAULT_CHECKIN_BAGTAG' ? member.indexTabOne + 2 : '' "
                :autofocus="index === 0 && indexItem === 0 && member.BookingDetail.BookingDetailCode != '' ?  defaultCheckIn === 'DEFAULT_CHECKIN_BAGTAG' || defaultCheckIn === 'DEFAULT_CHECKIN_ALL' ? true : false : false"
                v-model="member.BagtagCode"
                placeholder="bagtag"
                @keyup.enter="nextForcus(defaultCheckIn === 'DEFAULT_CHECKIN_ALL' ? member.indexTabAll + 2 : defaultCheckIn === 'DEFAULT_CHECKIN_BAGTAG' ? member.indexTabOne + 2 : '' )"
              />
            </b-col>
            <b-col
              :md="isShowNationSexCheckIn == 1 ? 1 : 2"
              class="block w-100"
            >
              <b-form-input
                :tabindex="defaultCheckIn === 'DEFAULT_CHECKIN_ALL' ? member.indexTabAll + 3 : defaultCheckIn === 'DEFAULT_CHECKIN_LOCKER' ?member.indexTabOne + 2 : '' "
                :autofocus="index === 0 && indexItem === 0 &&  defaultCheckIn === 'DEFAULT_CHECKIN_LOCKER' && member.BookingDetail.BookingDetailCode != ''? true : false"
                v-model="member.LockerCode"
                placeholder="locker"
                @keyup.enter="nextForcus(defaultCheckIn === 'DEFAULT_CHECKIN_ALL' ? member.indexTabAll + 3 : defaultCheckIn === 'DEFAULT_CHECKIN_LOCKER' ?member.indexTabOne + 2 : '')"
              />
              <ez-icon
              v-if="isShowNationSexCheckIn != 1"
                icon="ezGolf-icon-trash"
                size="14"
                color="#EA5455"
                class="cursor-pointer ml-1"
                @click="removeMember(indexItem,index)"
              />
            </b-col>
            <b-col
              md="2"
              class="block"
              v-if="isShowNationSexCheckIn == 1"
            >
              <v-select class="w-100" v-model="member.SexCode" label="KeyValue" :options="listSex"
                :reduce="option => option.KeyCode"  :clearable="false"
              />
            </b-col>
            <b-col
              md="2"
              class="block"
              v-if="isShowNationSexCheckIn == 1"
            >
              <v-select class="w-100" v-model="member.NationalityCode" label="CountryNameVN" :options="countries"
                :reduce="option => option.ID" :clearable="false"
              />
              <ez-icon
                icon="ezGolf-icon-trash"
                size="14"
                color="#EA5455"
                class="cursor-pointer ml-1"
                @click="removeMember(indexItem,index)"
              />
            </b-col>
            
          </b-row>
        </div>
        <!-- <div style="margin-top: 20px">
          <b-form-group>
            <b-form-checkbox v-model="sendMailCheckin">
              <p class="mb-0">
                Gửi email cho khách
              </p>
            </b-form-checkbox>
          </b-form-group>
        </div> -->
      </b-card-body>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="none"
            size="md"
            class="float-right"
            @click="hideModal"
          >
          {{ $t('golf_common_back') }}
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Ctrl + S"
            variant="primary"
            size="md"
            class="float-right mr-1"
            @click="checkIn_ICI01"
          >
          {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>
import jquery from 'jquery'
import { booking } from '@/api/booking'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
export default {
  components: { UserAvatar, ShortKey },
  props: [
    'items',
    'defaultPrintBDCinCheckIn',
    'defaultCheckIn',
    'TimeDelayScanBDC',
    'countries',
    'listSex',
    'NationalityCodeDefault',
    'isShowNationSexCheckIn'
  ],
  data() {
    return {
      sendMailCheckin: false,
      isOpen: false,
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      allChildComponentClosed: [],
      inputForcus: false,
      isCheckAll: false,
      iTab: 1
    }
  },
  watch: {
    isOpen(val) {
      if(val) {
        console.log(this.items)
        var indexAll = 0
        var indexOne = 0
        this.items.forEach((x,indexItem) => {
          x.Members.forEach((y,indexMember) => {
            if(indexItem === 0 && indexMember === 0) {
              y.indexTabAll = indexAll
              y.indexTabOne = indexOne
            } else {
              indexAll += 3
              indexOne += 2
              y.indexTabAll = indexAll
              y.indexTabOne = indexOne
            }
            if(!y.NationalityCode) {
              y.NationalityCode = this.NationalityCodeDefault
            }
            if(!y.SexCode) {
              y.SexCode = 'SEX_M'
            }
          })
        })
        if(this.defaultPrintBDCinCheckIn == 1) {
          this.isCheckAll = true
          this.checkAll(this.isCheckAll)
        } else {
          this.isCheckAll = false
          this.checkAll(this.isCheckAll)
        }
      }
      this.$emit('is-open', {ComponentId: "modal-checkin",IsActive:val});
      this.inputForcus = true
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-checkin')
    },
    async checkIn_ICI01() {
      const listBookingDetailIdPrintBDC = []
      const BookingDetail = []
      for (const item of this.items) {
        for (const member of item.Members) {
          if(member.Checked) {
            listBookingDetailIdPrintBDC.push(member.BookingDetail.Id)
          }
          BookingDetail.push({
            Id: member.BookingDetail.Id,
            BagtagCode: member.BagtagCode,
            LockerCode: member.LockerCode,
            SendSMS: this.sendMailCheckin,
            SendEmail: this.sendMailCheckin,
            Bdc: member.BDC.trim(),
            NationalityCode: member.BookingDetail.NationalityCode != member.NationalityCode || member.BookingDetail.SexCode != member.SexCode ? member.NationalityCode : null,
            SexCode: member.BookingDetail.NationalityCode != member.NationalityCode || member.BookingDetail.SexCode != member.SexCode ? member.SexCode : null
          })
        }
      }
      const data = {
        BookingDetail,
      }
      if(BookingDetail.length > 0) {
        const response = await booking.checkIn(data)
        this.showResToast(response)
        if (response.Status == 200) {
          this.$bvModal.hide('modal-checkin')
          this.$emit('event', { type: 'after-checkin' })
          if(listBookingDetailIdPrintBDC.length > 0) {
            this.$emit('printBDC', { data: listBookingDetailIdPrintBDC })
          }
        }
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_one_person_printbdc'))
      }
    },
    onTriggeredEventHandler(payload) {
      if(payload.evt.ctrlKey && payload.key === S) {
        this.checkIn_ICI01()
      }
    },
    checkAll(data) {
      this.items.forEach(x => {
        x.Checked = data
        x.Members.forEach(y => {
          y.Checked = data
        })
      })
    },
    checkItem(data) {
      this.items.forEach(x => {
        if(x.OpenDate === data.OpenDate) {
          x.Members.forEach(y => {
            y.Checked = data.Checked
          })
        }
      })
    },
    nextForcus(tabindex) {
      setTimeout(()=> {
        if(jquery('[tabindex="' + (tabindex+1)  + '"]')[0].placeholder != "BDC") {
          jquery('[tabindex="' + (tabindex+1)  + '"]')[0].focus()
        } else if(!jquery('[tabindex="' + (tabindex+1)  + '"]')[0]._value) {
          jquery('[tabindex="' + (tabindex+1)  + '"]')[0].focus()
        } else {
          jquery('[tabindex="' + (tabindex+2)  + '"]')[0].focus()
        }
      },this.TimeDelayScanBDC)
    },
    removeMember(indexItem,index) {
      this.items[indexItem].Members.splice(index, 1)
    }
  },
}
</script>

<style lang="scss">
#modal-checkin {
  .modal-checkin {
        width: calc(100vw*75/100);
        max-width: 890px
  }
  .block-header {
    .title-modal {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .color-primary {
    color: #114A9F;
  }

  .w-50 {
    width: 65px !important;
  }

  .color-warning {
    color: #FF9F43;
  }

  .content-body-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .fullname {
    width: 160px;
  }

  .block {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      padding: 0;
    }

    p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
}
</style>
