<template>
  <div>
    <b-modal
      id="modal-update-package"
      v-model="dialog"
      centered
      hide-header
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <div class="d-flex justify-content-start align-items-center">
            <p class="mb-0 title-modal color-primary mr-1">
              Thay đổi gói
            </p>
          </div>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModalUpdateHole"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md="12"
            class="px-0"
          >
            <div>
              <b-table
                ref="selectableTable"
                class="tbl-package"
                selectable
                select-mode="single"
                :items="packages.list"
                :fields="fieldstbl"
                responsive="sm"
                @row-selected="onRowSelected"
              >
                <template #cell(group)="data">
                  <ez-icon
                    icon="ezGolf-icon-golf-course"
                    :color="data.item.IsApply ? '#00d200' : 'rgb(103 103 103)'"
                    :size="18"
                  />
                </template>
                <template #cell(PackageName)="data">
                  {{ data.value }}
                </template>

                <template #cell(ExpirationDate)="data">
                  {{ data.item.StartDate ? utcConvertToLocalTimeZone(data.item.StartDate, 'DD/MM/YYYY - ' ) : '' }}
                  {{ data.item.ExpirationDate ? utcConvertToLocalTimeZone(data.item.ExpirationDate, 'DD/MM/YYYY' ) : 'Không thời hạn' }}
                </template>

                <template #cell(Player)="data">
                  <div
                    v-for="(i, ind) in data.item.Player"
                    :key="ind"
                  >
                    <div
                      :style="{ background: 'red'}"
                      class="dot-size-10 d-inline-block rounded-circle"
                    />
                    <span>{{ i.FullName }}</span>
                  </div>
                </template>

                <template #cell(PackageInfo)="data">
                  <div
                    v-for="(i, ind) in data.item.PackageInfo"
                    :key="ind"
                  >
                    {{ i.PackageGolfFee && i.PackageGolfFee.length > 0 ? i.PackageGolfFee.map(x => x.NumberOfHole + ' - '+ x.CourseName).join(', ') : '' }}
                  </div>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right ml-1"
            @click="save"
          >
            {{ $t('golf_common_confirm') }}
          </b-button>
          <b-button
            variant="none"
            size="md"
            class="float-right"
            @click="hideModalUpdateHole"
          >
          {{ $t('golf_common_back') }}
          </b-button>
          
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="dialog"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import VueSlider from 'vue-slider-component'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
// import {
//   BTableSimple, BTable, BCardHeader, BFormGroup, BFormInput, BModal, BCardBody, BForm, BRow, BCol, BFormTextarea,
//   BThead, BTr, BTh, BTd, BTbody, BFormCheckbox
// } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { booking } from '@/api/booking'
import dayjs from 'dayjs'

export default {
  name: 'UpdatePackage',
  components: {
    // BModal, BCardHeader, BFormInput, BFormGroup, BCardBody, BForm,  ValidationProvider, ValidationObserver,
    // BRow, BCol, BFormTextarea, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormCheckbox,
    vSelect, VueSlider,ShortKey
  },
  props: [
    'data',
  ],
  data() {
    return {
      packages: {
        list: [],
      },
      selected: [],
      reason: {
        list: [],
        selected: null,
      },
      isIncludedTax: true,
      isFreeTax: true,
      dialog: false,
      allChildComponentClosed: [ ],
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S]
    }
  },
  computed: {
    fieldstbl() {
      return [
        // { key: 'group', label: this.$t('golf_package_group') },
        // { key: 'PackageName', label: this.$t('golf_package_name') },
        // { key: 'PlayNumber', label: this.$t('golf_package_play_number') , class: 'text-right'},
        // { key: 'ExpirationDate', label: this.$t('golf_package_expiration_date'), class: 'text-right' },
        // { key: 'Player', label: this.$t('golf_package_player') },
        // { key: 'PackageInfo', label: this.$t('golf_package_packageInfo') },

        { key: 'group', label: 'Nhóm' },
        { key: 'PackageName', label: 'Tên gói' },
        { key: 'PlayNumber', label: 'Số lượt chơi', class: 'text-right' },
        { key: 'ExpirationDate', label: 'Thời hạn', class: 'text-right' },
        { key: 'Player', label: 'Người thụ hưởng' },
        { key: 'PackageInfo', label: 'Áp dụng phân bổ' },
      ]
    },
  },
  watch: {
    data(val) {},
    dialog(val) {
      this.$emit('is-open', {ComponentId: "modal-update-package",IsActive:val});
    }
  },
  async mounted() {},
  methods: {
    open() {
      if (!this.checkPermission('UBK04')) {
        return
      }
      this.dialog = true
      this.getPackage_api_RME05()
    },
    onRowSelected(items) {
      this.selected = items
    },
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    hideModalUpdateHole() {
      this.$bvModal.hide('modal-update-package')
    },
    async save() {
      if (this.selected.length > 0) {
        const body = {
          BookingDetail: {
            Id: this.data.BookingDetail.Id,
            PackageId: this.selected[0].PackageId,
          },
          Type: 'UPDATE',
        }
        const response = await booking.api_UBK04(body)
        this.showResToast(response)
        if (response.Status === '200') {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-update-package')
            this.$emit('event', { type: 'after-update-booking-package' })
          })
        }
      }
    },
    async getPackage_api_RME05() {
      const body = {
        Member: {
          Id: this.data.BookingDetail.MemberId,
        },
        OpenDateTime: this.data.BookingDetail.OpenDate,
        BookingDetailId: this.data.BookingDetail.Id,
      }
      const response = await booking.api_RME05(body)
      if (response.Status === '200') {
        this.packages.list = response?.Data?.MemberPackage
        this.selected = [response?.Data?.MemberPackage[0]]
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.save()
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/variables';
@import '@core/scss/vue/libs/vue-slider.scss';
::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 900px;
  }
  .b-table-sticky-header {
    overflow-y: auto !important;
    max-height: 85% !important;
  }
}
.banned-background-color-primary {
  background: rgba(17, 74, 159, 0.12);
}
.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}
.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.color-primary {
  color: $primary;
}
.header-table-custom {
  th {
    background: #F2F3F7;
    border-bottom: unset;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 10px;
  }
}
.body-table-custom {
  .header-board td {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #5D5F65;
    padding: 10px;
  }
}
.disabled {
  background: rgba(193, 193, 193, 0.12)
}
</style>
